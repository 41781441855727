import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import "./styles/Home.css";
const HomePage = lazy(() => import("./Pages/HomePage"));
const ListingPage = lazy(() => import("./Pages/ListingPage"));
const NFT = lazy(() => import("./Pages/NFT"));

export default function Home() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/selling/:addys" element={<ListingPage />} />
          <Route path="/nft/:listingid" element={<NFT />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
