import React, { FC, useMemo } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Polygon } from "@thirdweb-dev/chains";
import {
  ChainId,
  ThirdwebProvider,
  metamaskWallet,
} from "@thirdweb-dev/react";
import "./styles/globals.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ThirdwebProvider
      activeChain={Polygon}
      clientId="e9094b8a53d6a63e63cf11b87ea1ff3f"
      supportedWallets={[metamaskWallet()]}
      supportedChains={[Polygon]}
    >
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);
